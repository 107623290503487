<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar</v-btn
          >
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table 
        :headers="header" 
        :items="objects" 
        :search="search" 
        dense 
        locale="pt-BR"
        :items-per-page="10"
      >
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Etapas">
            <v-btn icon small tile>
              <v-icon @click="stepPlan(item.id)">mdi mdi-format-list-checkbox</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <!-- CADASTRO -->
    <TheDialog
      v-model="dialog"
      :title="title"
      :icon="pageIcon"
      width="1200px"
      @close-dialog="loadData"
    >
      <template>
        <v-row>
          <v-col cols="12">
            <v-form v-model="valid" ref="form1">
              <v-row>
                <v-col>
                  <b> Nome <sup>*</sup></b>
                  <v-text-field
                    placeholder="Name"
                    v-model="object.name"
                    class="my-2"
                    dense
                    outlined
                    color="primary"
                    :rules="[requiredRule, spaceRule]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b> Tipo de Plano </b>
                  <v-select
                    :items="type"
                    item-text="name"
                    item-value="value"
                    v-model="object.type"
                    class="my-2"
                    dense
                    hide-details
                    outlined
                    color="primary"
                  ></v-select>
                </v-col>

                <v-col cols="4">
                  <b> Prazos </b>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="object.validate"
                        placeholder="Data"
                        persistent-hint
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        @input="date = parseDate(object.validate)"
                        v-on="on"
                        hide-details
                        dense
                        outlined
                        class="mt-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        :rules="requiredRule"
                        v-model="date"
                        no-title
                        @input="menu1 = false"
                    ></v-date-picker>
                </v-menu>
                </v-col>

                <v-col cols="4">
                  <b> Responsável </b>
                  <v-select
                    :items="people"
                    item-text="name"
                    item-value="id"
                    v-model="object.idPeople"
                    class="my-2"
                    dense
                    hide-details
                    outlined
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b> Vulnerabilidades </b>
                  <v-select
                    placeholder="Vulnerabilidades"
                    :items="vulnerability"
                    item-text="name"
                    item-value="id"
                    v-model="object.vulnerabilityList"
                    class="my-2"
                    dense
                    hide-details
                    multiple
                    clearable
                    outlined
                    color="primary"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" color="primary" x-small>
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <sup v-if="index === 1" class="grey--text text-caption">
                        (+{{ object.vulnerabilityList.length - 1 }})
                      </sup>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="object.type === 'Específico'">
                <v-col>
                  <b> Setor </b>
                  <v-select
                    :items="sector"
                    item-value="id"
                    item-text="name"
                    v-model="object.sectorList"
                    class="my-2"
                    dense
                    hide-details
                    outlined
                    clearable
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" color="primary" x-small>
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <sup v-if="index === 1" class="grey--text text-caption">
                        (+{{ object.sectorList.length - 1 }})
                      </sup>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <b> Descrição </b>
                  <VueEditor
                    v-model="object.description"
                    :editor-toolbar="customToolbar"
                    class="mt-2"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>          
        </v-row>
      </template>
      <template v-slot:actions="">
        <v-btn
          small
          depressed
          color="primary"
          class="mt-2"
          dark
          @click="resolveForm()"
        >
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Atualizar</span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from "axios"
import { baseApiUrl, showError } from "@/global"
import TheTitle from "@/components/TheTitle"
import TheDialog from "@/components/TheDialog"
import TheToolTip from "@/components/TheToolTip"
import { VueEditor } from "vue2-editor"
import { mask } from "vue-the-mask"
import moment from 'moment'

export default {
  name: "Planos",
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
    VueEditor,
  },
  directives: {
    mask,
  },
  data: () => ({
    baseRoute: "plan",
    title: "Plano de Ação",
    pageIcon: "mdi-file-certificate-outline",
    
    menu1: false,
    date: null,

    dialog: false,
    valid: false,
    error: false,
    edit: false,
    search: null,

    object: {},
    people: {},
    plan: {},
    vulnerability: [],
    objects: [],
    sector: [],

    header: [
      { text: "Nome", value: "name" },
      { text: "Ações", value: "action", width: "10%" },
    ],

    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],

    type: [
      { name: "Geral", value: "Geral", id: "0" },
      { name: "Específico", value: "Específico", id: "1" },
    ],

  }),

  watch: {
    date() {
      this.object.validate = this.date
      this.object.validate = moment(this.object.validate).format('DD/MM/YYYY')
    },
  },

  computed: {
    getPersonName() {
      return function(idPerson) {
        const person = this.people.find((el) => el.id === idPerson);
        if (person) {
          return person.name;
        } else {
          return "";
        }
      };
    },
  },

  methods: {
        
    loadData() {
      const url = `${baseApiUrl}/${this.baseRoute}`;
      this.$store.dispatch("setLoadingInfo", true);
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch("setLoadingInfo", false);
          this.objects = res.data;
          // this.loadSteps(res.data.id); 
        })
        .catch(showError);
    },

    loadPeople() {
      const url = `${baseApiUrl}/people`
      return axios
        .get(url)
        .then((res) => {
          this.people = res.data
        })
        .catch(showError)
    },       

    loadVulnerability() {
      const url = `${baseApiUrl}/vulnerability`
      axios
        .get(url)
        .then((res) => {
          this.vulnerability = res.data
        })
        .catch(showError)
        
    },
    
    loadSector() {
      const url = `${baseApiUrl}/sector`
      axios
        .get(url)
        .then((res) => {
          this.sector = res.data
        })
        .catch(showError)
    },  

    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },

    dialogUpdate(item) {
      this.object = item
      this.object.idPerson = item.idPeople
      this.object.deadline = item.validate
      this.edit = true
      this.dialog = true
    },

    resolveForm() {
      this.$refs.form1.validate()
      if (!this.valid) {
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text:
            "Os campos não foram preenchidos corretamente, você pode verificar?",
          button: "Ok!",
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },

    resolveFormStep() {
      this.$refs.form2.validate()
      if (!this.valid) {
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text:
            "Os campos não foram preenchidos corretamente, você pode verificar?",
          button: "Ok!",
        })
        return
      }
      if (this.creatingStep) {
        this.saveStep()
      } else {
        this.updateStep()
      }
    },

    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object

      delete objects.id
      delete objects.vulnerability
      delete objects.idPerson
      delete objects.deadline
      delete objects.steps
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      
      this.$store.dispatch("setLoadingInfo", true)
      axios
        .put(url, objects)
        .then(() => {
          this.dialog = false
          this.loadData()
        })
        .catch(showError)
        .finally(() => {
          this.$store.dispatch("setLoadingInfo", false)
          this.dialog = false
        })
    },

    remove(id) {
      if (confirm("Remove?")) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },

    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.substring(0, 11).split("/")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },

    stepPlan(id) {
      const route = {
        name: 'Etapas',
        params: { id: id },
      }
      this.$router.push(route)
    },

  },

  async mounted() {
    await this.loadData()
    this.loadSector()
    await this.loadPeople()
    this.loadVulnerability()
  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>
